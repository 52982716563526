<template>
  <div class="datepicker-wrap" :id="componentId">
    <datepicker
    placeholder="전체 기간"
    :circle="true"
    v-model="dates"
    lang="ko"
    firstDayOfWeek="sunday"
    :range="true"
    class="f-datepicker"
    @input="$emit('input', toISOStringDates)"
    ref="dp"
    />
    <div class="filter-days">
      <sp-button
      class="gap"
      type="button"
      :class="{primary: JSON.stringify(day.date) === JSON.stringify(dates)}"
      v-for="(day, i) in days"
      :key="i+componentKey"
      @click.prevent="setDate(day.date)">
        {{day.name}}
      </sp-button>
    </div>
  </div>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'

export default {
  name: 'SpRangeDatepicker',
  props: [
    'value',
    'filterDays',
    'id',
  ],
  data () {
    return {
      componentKey: '',
      dates: [],
      days: [],
      uuid: null,
    }
  },
  created () {
    this.init()
  },
  mounted () {
    if (this.$refs.dp.$el.firstChild?.firstChild?.placeholder) {
      this.$refs.dp.$el.firstChild.firstChild.id = this.uuid
    }
    const filterArea = this.$el.querySelector('.filter-days').getClientRects()
    if (filterArea) {
      this.$el.style.setProperty('--dynamic-width', `${300 + filterArea[0].width}px`)
    }
  },
  computed: {
    componentId () {
      if (this.id) {
        return this.id
      }
      return this.uuidv4()
    },
    toISOStringDates () {
      return this.dates.map(day => {
        if (day instanceof Date) {
          return day.toISOString()
        } else {
          return day
        }
      })
    },
    attrs () {
      return this.$attrs
    },
  },
  methods: {
    forceRerender () {
      this.componentKey = this.uuidv4()
    },
    init () {
      if (this.value instanceof Array) {
        this.dates = this.value
      }
      if (this.filterDays instanceof Array) {
        this.filterDays.forEach(el => {
          const dateTo = new Date(new Date().setHours(0, 0, 0, 0))
          const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
          if (el.day > 0) {
            dateTo.setDate(dateTo.getDate() - el.day + 1)
          } else {
            dateFrom.setDate(dateFrom.getDate() - el.day - 1)
          }
          const day = {
            name: el.name,
            date: [
              dateTo.toISOString(),
              dateFrom.toISOString(),
            ],
          }
          if (el.day == 0) day.date = [null, null]

          if (el.default && this.dates.length == 0) {
            this.dates = day.date
          }
          this.days.push(day)
        })
      }
      if (this.dates.length == 0) {
        this.dates = [null, null]
      }
    },
    setDate (date) {
      this.dates = date
      this.$emit('input', this.dates)
    },
    reset () {
      this.filterDays.forEach(el => {
        if (el.default) {
          const dateTo = new Date(new Date().setHours(0, 0, 0, 0))
          const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
          if (el.day > 0) {
            dateTo.setDate(dateTo.getDate() - el.day + 1)
          } else {
            dateFrom.setDate(dateFrom.getDate() - el.day - 1)
          }
          const day = {
            name: el.name,
            date: [
              dateTo.toISOString(),
              dateFrom.toISOString(),
            ],
          }
          if (el.day == 0) day.date = [null, null]

          this.dates = [...day.date]
          this.forceRerender()
        }
      })
    },
  },
  watch: {
    value (newValue, prevValue) {
      if (JSON.stringify(newValue) === JSON.stringify(prevValue)) return
      this.dates = this.value
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
}
</script>
<style lang="scss">
:root {
  --v-calendar-active-bg-color: var(--theme-primary-color);
  --v-calendar-datepicker-icon-color: var(--theme-primary-color);
  --v-calendar-range-bg-color: #edf0ff;
  --dynamic-width: 300px;
  --v-calendar-input-bg-disable-color: #f9f9f9;
}

.datepicker-wrap{
  text-align: left;
  position: relative;
  z-index: 2;
  container-name: datepicker;
  container-type: inline-size;
  // container-type: size;
  // position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: var(--dynamic-width);
  min-width: 300px;
  max-width: 100%;
  vertical-align: top;
  margin: 0;
}

@container datepicker (width < 600px) {
  .v-calendar.f-datepicker .content {
    flex-direction: column;
    transform: translate(-10%, -17%) scale(73%) !important;
  }
}

.v-calendar.f-datepicker {
  display: inline-block;
  margin: 4px 2.5px;
}
.v-calendar .number {
  margin: -2px 0 0 -5px;
}
.v-calendar .button{
  border-radius: 0;
  margin: 0;
  line-height: 10px;
  font-size: 20px !important;
}
.v-calendar.f-datepicker .content {
  transform: translate(-11%, -20%) scale(73%);
}
.v-calendar.f-datepicker {
  display :inline-block;
  .input-field.long {
    min-width: 243px;
    input {
      color: #000;
      border-radius: 5px;
      border-color: #ddd;
      height: 22px;
      padding: 1px 10px 1px 40px;
    }
    input:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
      }
    }
  }
}
.filter-days {
  display: inline-block;
  vertical-align: top;
  //  margin: 4px 0;
  // margin: 0 4px;

  .button {
    // margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.button-days {
  font: 500 14px NanumSquareAc;
  cursor: pointer;
  /* vertical-align:top; */
  margin: 0 0 0 4px;
  height:26px;
  border-radius:5px;
  border:1px solid #ddd;
  background-color: #fff;
  background-image: linear-gradient(#fff, #f0f0f0);
  box-shadow: inset 0 1px 1px rgba(255,255,255,0.35),
              inset 0 -1px 1px rgba(0,0,0,0.35);
  line-height:14px;
}
</style>
