export default [

    //가상계좌 입급내역 페이지
    {
        path: '/settlement/deposit-history',
        name: 'VaDepositHistoryList',
        component: () => import('@/components/settlement/VaDepositHistoryList'),
        meta: {
            requiresAuth: true,
            fullScreen: false,
        }
    },

    //청구내역 페이지
    {
        path: '/settlement/billing-history',
        name: 'BillingHistory',
        component: () => import('@/components/settlement/BillingHistory'),
        meta: {
            requiresAuth: true,
            fullScreen: false,
        }
    },

]