const getDefaultState = () => {
    return {
      list: [],
      originFilter: {},
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 50
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
    },
    actions: {
      //청구내역 호출
      async getInvoiceList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/invoice`,
            payload,
          )
          response.data.invoiceContractList.forEach(i => i.expanded = i.invoiceChargeList.length > 0 && true   ) // 토글을 위해서 값추가

          commit('setResponse', response.data)
          commit('setOriginFilter', payload)
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },


      //입주사명 호출
      async getTenantList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/invoice/tenant`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },


      //청구내역 상세 조회 호출
      async getInvoiceDetail ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/invoice/invoice-charge/${payload.invoice_charge_id}`,
            payload.data,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },





      //명세서 다운로드용 데이터 호출
      async getInvoiceIssuance ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/invoice-issuance`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },







    },
  }
