<template>
  <span v-show="totalCount >= 0" class="infoBox">
    총 <span class="count">{{ getFormattedNumber(totalCount) }} </span>건의 데이터
  </span>
</template>

<script>
export default {
  name: 'SpCounter',
  props: {
    totalCount: Number,
  },
}
</script>

<style lang="scss" scoped>
.infoBox {
  margin:0 6px;
  line-height:35px;
  color:#333333;
  font-size: 13px;
  font-weight: 600;
  .count {
    color: var(--theme-primary-color);
  }
}
</style>
