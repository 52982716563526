const getDefaultState = () => {
  return {
    list: [],
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 입주사명 호출
    async getInvoiceTenant ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/common/filter/contract-tenant`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceTenant.json', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구월 호출
    async getInvoiceMonth ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/common/filter/billing-year-month`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceMonth.json', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 버전 호출
    async getInvoiceVersion ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/common/filter/invoice-version`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceVersion.json', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 청구내역 호출
    async getInvoiceList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/v2/invoice`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceListData.json', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}