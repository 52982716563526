<template>
    <span class="badge" :class="className">{{ name }}</span>
</template>
<script>
export default {
    name: 'SpBadge',
    props: {
        code: {
            type: String,
            default: String,
        },
        name: {
            type: String,
            default: String,
        }
    },
    computed: {
        className() {
            return this.code.toLowerCase()
        }
    }
}
</script>
<style lang="scss" scoped>
.badge {
    display: inline-block;
    min-width: 20px;
    padding: 0.19rem;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    background-color: var(--theme-primary-color);
    border-radius: 0.12rem;
}
</style>