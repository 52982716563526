<template>
<div class="gap">
  <div class="multiple-select" :class="{disabled}">
    <ul class="selected-items" tabindex="0">
      <!-- default (no selected) -->
      <li v-if="!checkedItems.length" class="selected wrap-item">
        <span class="item">{{ idToValue[source[keyName]][valueName] }}</span>
      </li>
      <!-- selected items -->
      <li
      @click="toggle(item)"
      @keyup.enter="toggle(item)"
      class="selected wrap-item"
      v-for="item in checkedItems" :key="item">
        <span class="item" tabindex="0">
          {{ idToValue[item][valueName] }}
          <ion-icon class="icon-close" name="close"></ion-icon>
        </span>
      </li>
    </ul>
    <ul class="all-items" @keyup.enter="toggle(source[keyName])" tabindex="0">
      <!-- select all (root) -->
      <li class="wrap-root">
        <div class="wrap-item" :class="{selected: !checkedItems.length}">
          <span
          class="item"
          @click.enter="toggle(source[keyName])">
            {{ idToValue[source[keyName]][valueName] }}
          </span>
        </div>
      </li>
      <li
      class="wrap-childs"
      tabindex="0"
      @keyup.stop.prevent.enter="toggle(childs[keyName])"
      v-for="(childs) in source[childsName]" :key="childs[keyName]">
        <!-- select all (childs) -->
        <div class="wrap-item leafs-all" :class="{selected: checkedItems.includes(childs[keyName])}">
          <span
          @click="toggle(childs[keyName])"
          @keyup.enter="toggle(childs[keyName])"
          class="item">{{ idToValue[childs[keyName]][valueName] }}
          <ion-icon class="icon-close" name="close"></ion-icon>
          </span>
        </div>
        <div>
          <ul class="leafs">
            <!-- select single leaf -->
            <li
            class="wrap-item"
            :class="{selected: checkedItems.includes(leaf[keyName])}"
            v-for="leaf in childs[childsName]" :key="leaf[keyName]">
              <input
              :id="`lf-${uuid}-${leaf[keyName]}`"
              type="checkbox"
              v-show="false"
              v-model="checkedItems"
              @click.stop.prevent="toggle(leaf[keyName])"
              :value="leaf[keyName]"/>
              <label
              class="item leaf-label"
              tabindex="0"
              @keyup.stop.prevent.enter="toggle(leaf[keyName])"
              :for="`lf-${uuid}-${leaf[keyName]}`">
                <span>{{leaf[valueName]}}</span>
                <ion-icon class="icon-close" name="close"></ion-icon>
              </label>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'SpMultipleSelect',
  props: {
    value: { type: Array, default: undefined },
    id: { type: String, default: '' },
    source: {
      type: Object,
      // required: true,
      default () {
        return {
          id: null,
          parentId: null,
          name: '',
          childs: [],
        }
      },
    },
    keyName: {
      type: String,
      default: 'id',
      required: false,
    },
    valueName: {
      type: String,
      default: 'name',
      required: false,
    },
    parentKeyName: {
      type: String,
      default: 'parentId',
      required: false,
    },
    childsName: {
      type: String,
      default: 'childs',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      uuid: null,
      checkedItems: [],
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  computed: {
    flatTree () {
      const treeToList = (tree) => {
        const result = [tree]
        tree[this.childsName].forEach(child => {
          const childResult = treeToList(child)
          result.push(...childResult)
        })
        return result
      }
      return treeToList(this.source)
    },
    flatTreeKV () {
      const treeKV = {}
      this.flatTree.forEach((e) => {
        treeKV[e[this.keyName]] = e
      })
      return treeKV
    },
    relations () {
      const relations = {}
      this.flatTree.forEach((e) => {
        relations[e[this.keyName]] = e[this.childsName]
      })
      return relations
    },
    idToValue () {
      // add root item into allItems
      const allItems = [{
        [this.keyName]: this.source[this.keyName],
        [this.valueName]: `${this.source[this.valueName] ? this.source[this.valueName] + ' ' : ''}전체`,
      }]
      const childs = Object.keys(this.source[this.childsName]).map(i => {
        // add leafs into allItems
        const temp = this.source[this.childsName][i][this.childsName]
        allItems.push(...temp)
        // return child it self to map
        return {
          [this.keyName]: this.source[this.childsName][i][this.keyName],
          [this.valueName]: `${this.source[this.childsName][i][this.valueName]}${temp.length > 0 ? ' 전체' : ''}`,
        }
      })
      // add childs into allItems
      allItems.push(...childs)

      const resultKV = {}
      allItems.forEach(e => {
        // make key-value object
        resultKV[e[this.keyName]] = {
          [this.keyName]: e[this.keyName],
          [this.valueName]: e[this.valueName],
        }
      })
      return resultKV
    },
  },
  methods: {
    toggle (id) {
      const index = this.checkedItems.indexOf(id)
      if (index == -1) {
        this.checkedItems = [...this.checkedItems, id]
      } else {
        this.checkedItems = [...this.checkedItems.filter(e => e !== id)]
      }
    },
  },
  watch: {
    value () {
      this.checkedItems = [...this.value]
    },
    checkedItems: {
      deep: true,
      handler (v, ov) {
        // 전체 선택 시 rootId 대신 빈 값으로 대체
        if (v.filter(x => [this.source[this.keyName]].includes(x)).length == 1) {
          this.checkedItems = []
          this.$emit('input', this.checkedItems)
          return
        }
        let temp = []
        let toDelete = []
        const toInsert = []
        const inserted = v.filter(x => !ov.includes(x))
        // const deleted = ov.filter(x => !v.includes(x))
        if (inserted.length) {
          for (const id of inserted) {
            const currentItem = this.flatTreeKV[id]
            // will remove childs form checkedItems
            toDelete = [...currentItem[this.childsName].map(a => a[this.keyName])]
            // will remove parent from checkedItems
            if (currentItem[this.parentKeyName] && v.includes(currentItem[this.parentKeyName])) {
              toDelete.push(currentItem[this.parentKeyName])
            }
            if (currentItem[this.parentKeyName]) {
              const bros = this.flatTreeKV[currentItem[this.parentKeyName]][this.childsName].map(a => a[this.keyName])
              // 형제가 모두 체크된 경우
              if (v.filter(x => bros.includes(x)).length == bros.length) {
                // 형제 모두 삭제 예정
                toDelete = [...toDelete, ...bros]
                if (currentItem[this.parentKeyName]) {
                  // 부모 추가 예정
                  toInsert.push(currentItem[this.parentKeyName])
                }
              }
            }
          }
          // 추가 및 삭제 반영
          temp = [...this.checkedItems.filter(x => !toDelete.includes(x)), ...toInsert]
          this.checkedItems = [...temp]
          this.$emit('input', this.checkedItems)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>

.icon-close {
  position:relative;
  top: 1px;
  right: -2px;
}
li {
  list-style-type: none;
}
.wrap-root {
  padding: 5px 0;
  .icon-close {
    float: right;
  }

}
.wrap-childs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: no-wrap;
  padding: 5px 0;
  border-top: 1px solid #ddd;
}
@container multiselect (max-width: 400px) {
  .wrap-childs {
    flex-wrap: wrap;
  }
}
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.multiple-select {
  position: relative;
  left: 2.5px;
  display: inline-block;
  width: calc(100% - 5px);
  box-sizing: border-box;
  --item-height: 16px;
  line-height: var(--item-height);
  max-width: 100%;
  &:focus-within {
    z-index: 100;
  }
  text-align: left;
  // vertical-align: top;
  vertical-align: middle;
  container-name: multiselect;
  container-type: inline-size;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    color: graytext;
    .selected-items {
      background: #eee;
      &:focus-within, &:focus {
        border: 1px solid #bbb;
        animation: none;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 9px;
        width: 0;
        height: 0;
        margin-top: -2.5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #bbb;
      }
    }
    .item {
      background: #bbb !important;
    }
    .all-items {
      display: none;
      transform: scale(0) !important;
    }
  }
  .selected-items {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: 4px 0;
    padding: 2px 9px 2px 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 9px;
      width: 0;
      height: 0;
      margin-top: -2.5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--theme-primary-color);
    }
    &:focus-within, &:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    &:focus+.all-items, &+.all-items:hover {
      transform: scale(1);
    }
    .icon-close {
      float: right;
    }
  }
  .all-items {
    position: absolute;
    // z-index: 2000;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    transform: scale(0);
    overflow: hidden;
    background: #fff;
    .wrap-childs {
      &:hover {
        box-shadow: inset 0px -10px 20px #8a8a8a22;
      }
    }
    &:focus-within {
      transform: scale(1);
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    .leafs-all {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis:200px;
      .icon-close {
        float: right;
      }
    }
    .leafs {
      display:flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0;
      .wrap-item {
        display: flex;
        box-sizing: border-box;
        width:200px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 200px;
      }
      .leaf-label {
        display: flex !important;
        overflow: hidden;
        min-width: 0;
        height: initial !important;
        // line-height: 16px;
        line-height: var(--item-height);
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .icon-close {
          flex-shrink: 0;
        }
      }
    }
  }
  .wrap-item {
    display: inline-block;
    margin: 0;
    padding-left: 9px;
    border: 0;
    .item {
      display: inline-block;
      box-sizing: border-box;
      margin: 1px 0;
      padding: 1px 4px;
      border: 0;
      border-radius: 5px;
      font-weight: 500;
      word-break: break-all;
      // line-height: 16px;
      line-height: var(--item-height);
      text-indent: 0;
      cursor: pointer;
      ion-icon {
        visibility: hidden;
      }
    }
  }
  .selected {
    .item {
      color: #fff;
      background: var(--theme-primary-color);
      ion-icon {
        visibility: visible;
      }
    }
  }
  @keyframes shadow {
    to {
      box-shadow: 0 0 6px var(--theme-primary-color);
    }
  }
}
</style>
