const getDefaultState = () => {
  return {
    list: [],
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 입주사명 호출
    async getUnpaidTenant ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/common/filter/contract-tenant`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceTenant.json', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 청구내역 호출
    async getUnpaidList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/v2/delay-damages`, payload)
        // response = await this._vm.get('../src/store/modules/invoice/testInvoiceUnpaidData.json', payload)
        // response.contract.forEach(i => i.expanded = i.invoiceChargeList.length > 0 && true ) // 토글을 위해서 값추가
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}