<template>
    <div class="file-wrap">
        <sp-button class="primary" @click.prevent.stop="openFile">추가</sp-button>
        <sp-input ref="fileInput" type="file" v-model="file" accept="image/*" @change="changeImageFile" style="display: none;" />
        <div v-if="fileImages.length > 0" class="files">
            <div class="form-inline" v-for="(file, index) in fileImages" :key="index">
                <span class="file-name">{{ file.name }}</span>
                <sp-button class="red" @click.prevent.stop="deleteItem($event, fileImages, index)">삭제</sp-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SpButtonFileInput',
    data() {
        return {
            file: null,
            fileImages: [],
        }
    },
    props: {
        formats: {
            type: Array,
            default: () => {
                return ['JPG', 'JPEG', 'GIF', 'PNG', 'WEBP']
            }
        },
        images: {
            type: Array,
            default: Array,
        }
    },
    methods: {
        openFile() {
            this.$refs.fileInput.$refs.input.click()
        },
        changeImageFile($event) {
            const { files } = $event.target
            if (files.length <= 0) {
                return
            }

            const file = files[0]
            
            if (!Object.values(files).every(x => x.type.includes('image'))) {
                this.alert({
                    title: '',
                    description: `해당 파일은 이미지 파일이 아닙니다.\n이미지 파일을 업로드 해주세요`
                })
                this.file = null
                return
            }

            const reader = new FileReader()

            reader.onload = (e) => {
                const result = e.target.result
                // 추출한 결과 값을 처리하는 로직을 작성합니다.
                this.fileImages.push({
                    name: file.name,
                    result: result,
                })
            }

            reader.readAsDataURL(file)
            this.file = null
            this.$emit('update:images', this.fileImages)
        },
        deleteItem(_$event, baseItems, index) {
            baseItems.splice(index, 1)
            this.$emit('update:images', baseItems)
        }
    },
    created() {
        this.fileImages = this.images
    }
}
</script>
<style lang="scss" scoped>
.file-wrap {
    display: flex;
    .files {
        flex: 1 1 auto;
        width: 100%;
        margin-left: 8px;
        .file-name {
            margin-right: 8px;
        }
    }
}
</style>