export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getTenantList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        // async getTenantList(context, payload) {
        //     let response = null

        //     try {
        //         response = await this._vm.get(
        //             `${this._vm.SPARKONE_OFFICE_API_URL}tenant`
        //             , payload
        //         )
        //     } catch (error) {
        //         if (error.response) {
        //             response = error
        //         } else {
        //             throw error
        //         }
        //     }

        //     return response
        // },
        async getTenantInfo(context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/${payload.pathParam}`,
                    payload.queryParam
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postTenant(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARKONE_OFFICE_API_URL}tenant`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putTenant(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARKONE_OFFICE_API_URL}tenant/${payload.tenantId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getCorporationRegistrationNumberInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}corporationRegistrationNumber/${payload.corporationRegistrationNumber}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },


        //[오피스 입주사] 부서 가져오기
        async getDepartmentList({ commit }, payload,) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/department`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        
        //[오피스 입주사] 부서 등록/수정
        async putDepartmentList({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/department?membershipId=${payload.params.membershipId}&managerType=${payload.params.managerType}&tenantId=${payload.params.tenantId}&managerId=${payload.params.managerId}`
                    , payload.groups
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },

        //[오피스 입주사] 부서 삭제
        async deleteDepartmentList({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.delete(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/department/${payload.id}`
                    ,payload.params
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },

        //[오피스 입주사] 직책 가져오기
        async getPositionList({ commit }, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/position`
                    , payload
                )

            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },

        //[오피스 입주사] 직책 등록/수정
        async putPositionList({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/position?membershipId=${payload.params.membershipId}&managerType=${payload.params.managerType}&tenantId=${payload.params.tenantId}&managerId=${payload.params.managerId}`
                    ,payload.positions
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },

        //[오피스 입주사] 직책 삭제
        async deletePositionList({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.delete(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/tenant/position/${payload.id}`
                    ,payload.params
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },



    },
}
