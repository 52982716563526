<template>
    <div class="gap">
      <div class="wrap-input">
        <textarea
        ref="textarea"
        :class="{ plaintext: plaintext }"
        :rows="rows"
        :value="value"
        :id="uuid"
        @input="input"
        v-on="lisnters"
        v-bind="attrs"
        :disabled="plaintext || disabled"></textarea>
      </div>
    </div>
    </template>
    
    <script>
    export default {
      name: 'SpInput',
      inheritAttrs: false,
    
      props: {
        value: { type: null, default: undefined },
        id: { type: String, default: '' },
        rows: { type: [Number, String], default: 3},
        plaintext: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
      },
      data () {
        return {
          uuid: null,
        }
      },
      created () {
        this.uuid = this.id
        if (this.uuid == '') {
          this.uuid = this.uuidv4()
        }
      },
      methods: {
        input (event) {
          this.$emit('input', event.target.value)
        },
        focus () {
          this.$refs.textarea.focus()
        },
      },
      computed: {
        lisnters () {
          const { input, ...listeners } = this.$listeners
          return listeners
        },
        attrs () {
          return this.$attrs
        },
      },
    }
    </script>
    
    <style lang="scss" scoped>
    .gap {
      max-width: 100%;
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 20px;
      box-sizing: border-box;
    }
    .wrap-input {
      position: relative;
      margin: 0 auto;
      width: calc(100% - 5px);
      box-sizing: border-box;
    }
    textarea {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      width: 100%;
      height: auto;
      font-family: inherit;
      padding: 4px 8px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin: 4px 0;
      outline: none;
      resize: none;
      &.plaintext {
        padding: 4px 0;
        border-color: transparent;
        &:disabled {
          background-color: transparent;
        }
      }
    }
    textarea:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
        /* box-shadow: 0 0 6px red; */
      }
    }
    .hide-caret {
      caret-color: transparent !important;
    }
    </style>
    