<template>
  <table ref="table">
    <slot />
  </table>
</template>

<script>
export default {
  mounted () {
    this.$nextTick(() => {
      this.observer = new MutationObserver(() => { this.setLabel() })
      this.observer.observe(
        this.$el.querySelector('table > tbody'),
        {
          characterData: true,
          subtree: true,
          childList: true,
        },
      )
      this.$el.querySelectorAll('table > thead th').forEach(e => this.ths.push(e.innerText))
      this.setLabel()
    })
  },
  data () {
    return {
      observer: null,
      ths: [],
    }
  },
  methods: {
    setLabel () {
      for (const el of this.$el.querySelectorAll('tbody tr')) {
        for (const [i, th] of this.ths.entries()) {
          if (th) {
            el.children[i]?.setAttribute('data-label', th)
          }
        }
      }
    },
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
}
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  word-wrap: break-word;
}
table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  thead {
    user-select: none;
    th {
      box-sizing: border-box;
      position: sticky;
      // align-self: auto;
      z-index: 1;
      top: 0;
      padding: 5px 10px;
      font-size: 11.5px;
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      // color: #444;
      color: #212121;
      text-align: initial;
      vertical-align: middle;
      border-bottom: 1px solid #BDBDBD;
      // background-color: #ffffff99;
      background-color: #F6F7F9;
      // backdrop-filter: blur(3px);
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      &:after {
        // transform: translateY(40%);

      }
    }
    :first-child {
      // text-align: center;
    }
  }
  &::v-deep tbody {
    text-align: initial;
    white-space: nowrap;
    tr {
      &:nth-child(even) {
        // background: #fefefe99;
        // background: #f2f2f2aa;
        // background: #f3f3f333;
      }
      &:hover {
        // background: #efefefee;
        box-shadow: inset 0px -10px 20px #8a8a8a22;

      }
      td {
        border-bottom: 1px solid #E0E0E0;
        line-height: 30px;

        font-weight: 600;
        font-size: 13.5px;
        padding: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        word-wrap: break-word;
        vertical-align: middle;
        &:first-child {
          // text-align: center;
        }
      }
    }
  }
}
/** 컬럼 소팅 */
th.sort-by:before, th.sort-by:after {
  position: absolute;
  border: 6px solid transparent;
  content: "";
  display: block;
  // box-sizing: border-box;
  height:42px;
  left:auto;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 0;
  background: transparent;
  overflow: hidden;
  }
th.sort-by.text-right {
  text-indent: 10px;
}
th.sort-by.text-right:before,
th.sort-by.text-right:after {
  left:5px;
  right: auto;
}
th.sort-by:before { border-bottom-color: #bbb; margin-top: -35px; }
th.sort-by:after { border-top-color: #bbb; margin-top: 21px; }
th.sort-by.desc:before { border-bottom-color: #bbb; }
th.sort-by.desc:after { border-top-color: var(--theme-primary-color); }
th.sort-by.asc:before { border-bottom-color: var(--theme-primary-color); }
th.sort-by.asc:after { border-top-color: #bbb }

@media screen and (max-width: 600px) {
  table {
    border: 0;
    colgroup {
      display:none;
    }
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    background: red;
  }

  table tr {
    display: block;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px 0;
    padding : 10px;

  }

  table td {
    display: block;
    text-align: right !important;
    min-height: 30px;
  }
  table td:not([data-label]) {
    display: none;
  }
  table td::before {
    content: attr(data-label);
    margin-right: 10px;
    float: left;
    font-weight: 900;
  }

}
</style>
