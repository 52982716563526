export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/account/membership`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/account/contract`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAdditionalList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/account/additional`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContract ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/account/contract/${payload.contractId}`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractAdditional ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/account/additional/${payload.contractId}`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
