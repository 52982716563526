export default [
    {
        path: '/usage-history/visit',
        name: 'UsageVisitPage',
        component: () => import('@/components/usage/VisitPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/meeting-room',
        name: 'UsageMeetingRoomPage',
        component: () => import('@/components/usage/MeetingRoomPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/qr-access',
        name: 'UsageQRccessPage',
        component: () => import('@/components/usage/QRAccessPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/seat-reservation',
        name: 'UsageSeatReservationPage',
        component: () => import('@/components/usage/SeatReservationPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/meeting-room-reservation',
        name: 'UsageMeetingRoomReservationPage',
        component: () => import('@/components/usage/MeetingRoomReservationPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/guest-invitation',
        name: 'UsageGuestInvitationPage',
        component: () => import('@/components/usage/GuestInvitationPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/guest-qr-access',
        name: 'UsageGuestQRAccessPage',
        component: () => import('@/components/usage/GuestQRAccessPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/attendance',
        name: 'UsageAttendancePage',
        component: () => import('@/components/usage/AttendancePage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
    {
        path: '/usage-history/app-login',
        name: 'UsageAppLoginPage',
        component: () => import('@/components/usage/AppLoginPage'),
        meta: {
            requiresAuth: true,
            fullScreen: false
        }
    },
]