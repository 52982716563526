<template>
  <div class="form-inline">
    <sp-select
    v-model="startValue"
    :style="{ width: width , minWidth: width }"
    :required="isRequired"
    v-validity="isRequired && !isValueCorrect('start') ? `${requiredNotice}`: ''"
    :disabled="disabled">
      <option
      :value="item.start"
      :key="i"
      v-for="item, i in getOptions">
        {{ item.startText }}
      </option>
    </sp-select>
    <span v-if="!isPeriodTypeTime" style="margin-left:2px;">일전</span>
    ~
    <sp-select
    v-model="endValue"
    :style="{ width: width , minWidth: width }"
    :required="isRequired"
    v-validity="isRequired && !isValueCorrect('end') ? `${requiredNotice}`: ''"
    :disabled="disabled">
      <option
      :value="item.end"
      :key="j"
      v-for="item, j in getOptions">
        {{ item.endText }}
      </option>
    </sp-select>
    <span v-if="!isPeriodTypeTime" style="margin-left:2px;">일전</span>
  </div>
</template>
<script>
export default {
  name: 'SpSelectPeriodRange',
  data () {
    return {
      timeSlots: [],
      daySlots: [],
    }
  },
  props: {
    start: {
      type: [String, Number],
      default: String,
      required: true,
    },
    end: {
      type: [String, Number],
      default: String,
      required: true,
    },
    periodType: {
      type: String,
      default: 'time',
      validator: (value) => ['time', 'day'].includes(value),
    },
    max: {
      type: [String, Number],
      default: 14,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width () {
      return this.isPeriodTypeTime ? '75px' : '55px'
    },
    isRequired () {
      return this.required && !this.disabled
    },
    isPeriodTypeTime () {
      return this.periodType === 'time'
    },
    getOptions () {
      return this.isPeriodTypeTime ? this.timeSlots : this.daySlots
    },
    requiredNotice () {
      const _title = this.isPeriodTypeTime ? '시간을' : '기간을'
      return `${_title} 선택해주세요`
    },
    startValue: {
      get () {
        return this.start
      },
      set (newValue) {
        this.$emit('update:start', newValue)
      },
    },
    endValue: {
      get () {
        return this.end
      },
      set (newValue) {
        this.$emit('update:end', newValue)
      },
    },
  },
  methods: {
    initData () {
      const _daySlots = []
      for (let i = this.max; i >= this.min; i--) {
        const obj = {
          start: i,
          startText: i,
          end: i,
          endText: i,
        }
        _daySlots.push(obj)
      }

      this.timeSlots = this.generateTimeSlots()
      this.daySlots = _daySlots
    },
    isValueCorrect (key) {
      return this.getOptions.some(x => x[key] === this.$props[key])
    },
  },
  created () {
    this.initData()
  },
}
</script>
