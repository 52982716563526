export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        // 입주사 관리자 리스트 조회
        async getManagerList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/manager`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        // 입주사 관리자 상세 조회
        async getManagerInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/${payload.pathParam}/detail`
                    , payload.queryParam
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        // 입주사 관리자 상세 수정
        async putManagerInfo({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/${payload.pathParam}?membershipId=${payload.queryParam.membershipId}&managerType=${payload.queryParam.managerType}&tenantId=${payload.queryParam.tenantId}&managerId=${payload.queryParam.managerId}`
                    , payload.requestBody
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        // 입주사 관리자 상세 상태 수정
        async putManagerStatus({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/${payload.pathParam}/status?membershipId=${payload.queryParam.membershipId}&managerType=${payload.queryParam.managerType}&tenantId=${payload.queryParam.tenantId}&managerId=${payload.queryParam.managerId}`
                    , payload.requestBody
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        }
    },
}
