import md5 from "md5"
import aws from 'aws-sdk'
import moment from 'moment'

export default class UploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;

    /**
     * AWS 설정
     */
    if( !this.s3 ) {
      aws.config.update({
        region: process.env.VUE_APP_BUCKET_REGION,
        credentials: new aws.CognitoIdentityCredentials({
          IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID
        })
      })

      this.s3 = new aws.S3({
        apiVersion: "2006-03-01",
        maxRetries: 0,
        httpOptions: {
          timeout: 120000,
          connectTimeout: 5000
        },
        params: {
          Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET
        }
      })
    }
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then( file => new Promise(((resolve, reject) => {
      this._initRequest( file, resolve, reject );
    })))
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest( file, resolve, reject ) {

    /**
     * 기본 용량 제한 3Mb
     */
    let limit_size = 1024 * 1024 * 3

    /**
     * 용량 제한 있을 시 체크
     */
    if( parseFloat(file.size) > parseFloat(limit_size) )
      reject('허용 용량 초과 ' + String(Math.round(file.size / 1024 / 1024, 1)) + 'Mb > ' + String(Math.round(limit_size / 1024 / 1024, 1)) + 'Mb ')

    /**
     * 기본 경로 셋팅
     */
    let root_folder = 'ckeditor'
    let path = moment().format('YYYY/MM/DD')
    let upload_path = process.env.VUE_APP_MODE === 'production' ? 'production' : 'development/dev'
    upload_path += '/' + root_folder + '/' + path

    /**
     * 파일 정보 셋팅
     */
    const timestamp = moment().valueOf()
    let file_name = file.name
    let file_ext = file.name.split(".").pop()
    let content_type = file.type
    let temp_file_name = String(timestamp) + '_' + file_name
    temp_file_name = md5(temp_file_name) + '.' + file_ext

    /**
     * 업로드 URL 셋팅
     */
    let upload_url = process.env.VUE_APP_S3_STATIC_URL
    upload_url += process.env.VUE_APP_MODE === 'production' ? '' : '/dev'
    upload_url += '/' + root_folder + '/' + path + '/' + temp_file_name

    /**
     * s3 업로드 파라미터 셋팅
     */
    const params = {
      Body: file,
      Key: upload_path + '/' + temp_file_name,
      Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
      ContentType: content_type,
    }

    this.s3.putObject(params, (err, data) => {
      if( err )
        reject(err)

      resolve({
        default: upload_url
      })

    })

  }

}
