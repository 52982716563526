  export default {
    namespaced: true,
    actions: {
      async getMemberGroupInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/membership/member/group/${payload.membershipId}`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getTenantTotalSummaryData ({commit}, payload){//멤버십에 연결된 유효한 계약의 계약 합계 정보 추출
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/tenantTotalSummaryData/${payload.membershipId}`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getTenantSummaryList ({commit}, payload){//멤버십 입주사에 연결된 유효한 계약의 계약 합계 정보 추출
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/tenantSummaryData/${payload.membershipId}`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getContractTenant ({commit}, payload){//입주사 정보 추출
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/contractTenant`,
             payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async psotMemberLink ({commit}, payload){//멤버등록 링크 생성
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/psotMemberLink`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postRegistrationMember ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/postRegistrationMember`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postMemberExcelupload ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/postMemberExcelupload`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
    },
  }
