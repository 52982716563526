const getDefaultState = () => {
    return {
      list: [],
      count: 0,
      originFilter: {},
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 50
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data.qrHistoryList
        state.count = data.totalCount
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
    },
    actions: {
      // 이용내역 > QR출입내역 API
      async getList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/usage/qr/guest`,
            payload,
          )
          commit('setResponse', response.data)
          commit('setOriginFilter', payload)
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },



      // 이용내역 > QR출입내역 > 방문객 정보 개별조회 API
      async getDetail ({ commit }, payload) {
        
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/usage/qr/guest/${payload.guest_id}`,
            payload,
          )

        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },





    },
  }
