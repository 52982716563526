<template>
<div class="inputbox" :class="`theme-${theme}`">
  <label :for="uuid">
    <input
    v-if="Array.isArray(checked)"
    type="checkbox"
    :id="uuid"
    :value="value"
    :checked="checked.includes(value)"
    @input="multiCheck"
    v-on="listeners"
    v-bind="attrs" />
    <input
    v-else
    type="checkbox"
    :id="uuid"
    :value="value"
    :checked="checked == trueValue"
    @input="singleCheck"
    v-on="listeners"
    v-bind="attrs" />
    <span v-if="text">{{ text }}</span>
  </label>
</div>
</template>

<script>
export default {
  name: 'SpSwitchCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    value: { default: undefined },
    trueValue: { default: true },
    falseValue: { default: false },
    id: { type: String, default: '' },
    text: { type: String, default: String },
    checked: { required: false },
    theme: {
      type: String,
      default: 'primary',
      validator (value) {
        return ['primary', 'secondary', 'tertiary'].includes(value)
      },
    },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }

    if(Array.isArray(this.checked)) {
      this.$emit('input', this.checked)
    } else {
      this.$emit('input', this.checked == this.trueValue ? this.trueValue : this.falseValue)
    }
  },
  methods: {
    singleCheck () {
      if (this.checked != this.trueValue) {
        this.$emit('input', this.trueValue)
      } else {
        this.$emit('input', this.falseValue)
      }
    },
    multiCheck () {
      const checked = [].concat(this.checked)
      if (checked.includes(this.value)) {
        checked.splice(checked.indexOf(this.value), 1)
      } else {
        checked.push(this.value)
      }
      this.$emit('input', checked)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.inputbox label {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

[type="checkbox"] {
  appearance: none;
  position: relative;
  border: max(0.125em, 0.1em) solid #b8b8b9;
  border-radius: 1.25em;
  width: 2.75em;
  height: 1.5em;
  cursor: pointer;
  background-color: white;
}

[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  transform: scale(0.8);
  background-color: #b8b8b9;
  transition: left 250ms linear;
}

[type="checkbox"]:checked {
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
}

[type="checkbox"]:checked::before {
  background-color: white;
  left: 1.25em;
}

[type="checkbox"]:disabled {
  border-color: #b8b8b9;
  opacity: 0.7;
  cursor: not-allowed;
}

[type="checkbox"]:checked:disabled {
  background-color: #ddd;
}

[type="checkbox"]:disabled:before {
  background-color: #b8b8b9;
}

[type="checkbox"]:disabled + span {
  opacity: 0.6;
  cursor: not-allowed;
}

[type="checkbox"]:focus-visible {
  outline-offset: max(0.125em, 0.1em);
  outline: max(0.125em, 0.1em) solid var(--theme-primary-color);
}

[type="checkbox"]:enabled:hover {
  box-shadow: 0 0 0 max(0.25em, 0.2em) #fcfcfc;
}

.inputbox {
  display: inline-block;
  vertical-align: middle;

  &.theme-secondary label {
    [type="checkbox"]:checked {
      background-color: var(--theme-secondary-color);
      border-color: var(--theme-secondary-color);
    }
    [type="checkbox"]:focus-visible {
      outline: max(0.125em, 0.1em) solid var(--theme-secondary-color);
    }
  }
}
</style>
