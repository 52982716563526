export default {
  install (Vue) {
    Vue.directive('SpResizable', {
      bind (el, binding, vnode) {
        // for chrome fit-content overflow auto rendering bug fix
        vnode.context.$nextTick(() => {
          const table = el.closest('table')
          if (!table) return
          const cols = table.querySelectorAll('col:not(tr col)')

          // 가장 가까운 div Wrapper
          const wrapperElem = el.closest('div')
          if (wrapperElem.tagName != 'DIV') {
            return
          }
          const styles = window.getComputedStyle(wrapperElem)
          // 가로 overflow가 auto or scroll 인 경우
          if (
            styles.getPropertyValue('overflow') == 'auto'
            || styles.getPropertyValue('overflow') == 'scroll'
            || styles.getPropertyValue('overflow-x') == 'auto'
            || styles.getPropertyValue('overflow-x') == 'scroll'
          ) {
            const diff = (function* () {
              let i = 1
              while (true) yield (i *= -1)
            }())
            let timer
            let lastScrollLeft = 0
            wrapperElem.addEventListener('scroll', (e) => {
              const ScrollLeft = wrapperElem.scrollLeft
              if (timer !== null) {
                clearTimeout(timer)
              }
              if (lastScrollLeft != ScrollLeft) {
                timer = setTimeout(function () {
                  const curr = cols[0].getBoundingClientRect().width
                  cols[0].style.width = (curr + diff.next().value) + 'px'
                }, 10)
              }
              lastScrollLeft = ScrollLeft
            })
          }
        })
      },
      inserted (el, binding, vnode) {
        const nodeName = el.nodeName
        if (nodeName != 'COLGROUP') {
          console.error('Available only for colgroup.')
          return
        }
        const table = el.closest('table')
        if (!table) {
          console.error('The parent table of colgroup could not be found.')
          return
        }
        // :not() = Avoid nested table bugs
        const cols = table.querySelectorAll('col:not(tr col)')
        const ths = table.querySelectorAll('th:not(th th,tr table th)')
        const colNumberToWidth = {}
        let hasVariableWidthCol = false
        try {
          cols.forEach((col, index) => {
            const colWidth = col.style.width
            if (colWidth.slice(-2) == 'px') {
              colNumberToWidth[index] = parseInt(colWidth, 10)
            } else if (colWidth == '') {
              hasVariableWidthCol = true
            } else {
              throw Error('Col element width can only be used in px.')
            }
          })
        } catch (error) {
          console.error(error)
          return
        }
        if (!hasVariableWidthCol) {
          console.error('A colgroup with the SpResizable directive specified must have at least one variable-size col element whose width is not specified.')
          return
        }
        ths.forEach((th, index) => {
          if (Number.isInteger(colNumberToWidth[index])) {
            const resizer = document.createElement('div')
            resizer.style.position = 'absolute'
            resizer.style.top = 0
            resizer.style.bottom = 0
            resizer.style.right = 0
            resizer.style.width = '6px'
            resizer.style.background = 'linear-gradient(90deg, rgba(255,255,255,000) 0%, rgba(200,200,200,0.2) 100%)'
            resizer.style.cursor = 'col-resize'

            resizer.addEventListener('mousedown', (e) => {
              const clientX = (e.clientX) ? e.clientX : e.touches[0].clientX
              const refWidth = parseInt(cols[index].style.width, 10)
              document.body.style.cursor = 'col-resize'
              document.body.style.userSelect = 'none'
              el.__vueMouseMove__ = (e) => {
                const currentX = (e.clientX) ? e.clientX : e.touches[0]
                const calWidth = refWidth + currentX - clientX
                cols[index].style.width = (calWidth > 6 ? calWidth : '6') + 'px'
              }
              el.__vueMouseUp__ = () => {
                document.body.style.cursor = ''
                window.removeEventListener('mousemove', el.__vueMouseMove__)
                window.removeEventListener('mouseup', el.__vueMouseUp__)
              }
              window.addEventListener('mousemove', el.__vueMouseMove__)
              window.addEventListener('mouseup', el.__vueMouseUp__)
            })
            th.appendChild(resizer)
          }
        })
      },
      unbind (el) {
        document.body.style.cursor = ''
        window.removeEventListener('mousemove', el.__vueMouseMove__)
        window.removeEventListener('mouseup', el.__vueMouseUp__)
      },
    })

  },
}
