const getDefaultState = () => {
    return {
        /* list: null,
        count: 0,
        originFilter: {}, */
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        /* totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        }, */
    },
    mutations: {
        /* resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setResponse(state, data) {
            state.list = data.memberships
            state.count = data.totalCount
        },
        setOriginFilter(state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        }, */
    },
    actions: {
        async getUserList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}user`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getUserInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}user/${payload.userId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
