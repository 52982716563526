<template>
    <div class="schedule-row">
        <div class="form-inline">
            <sp-checkbox v-model="useYnValue" :id="`dayType_${uuid}`" :disabled="disabled" />
            <label :for="`dayType_${uuid}`">{{ dayText }}</label>
        </div>
        <sp-radio v-model="wholedaySetValue" value="Y" :id="`wholeDaySetYn_y_${uuid}`" :name="`wholeDaySetYn_${uuid}`" :disabled="!isUse || disabled" />
        <label :for="`wholeDaySetYn_y_${uuid}`">종일</label>
        <sp-radio v-model="wholedaySetValue" value="N" :id="`wholeDaySetYn_n_${uuid}`" :name="`wholeDaySetYn_${uuid}`" :disabled="!isUse || disabled" />
        <label :for="`wholeDaySetYn_n_${uuid}`">시간</label>
        <sp-select-period-range v-if="!isWholeDay" :start.sync="startValue" :end.sync="endValue" :disabled="!isUse || disabled" />
    </div>
</template>
<script>
import { WEEKEND } from '@/constants'
export default {
  name: 'SpScheduleRow',
  data () {
    return {
      useYnValue: false,
      uuid: this.uuidv4(),
    }
  },
  props: {
    dayType: {
      type: String,
      default: String,
    },
    useYn: {
      type: String,
      default: 'Y',
      validator: (value) => ['Y', 'N'].includes(value),
    },
    wholeDaySetYn: {
      type: String,
      default: 'Y',
      validator: (value) => ['Y', 'N'].includes(value),
    },
    start: {
      type: [String, Number],
      default: String,
      required: true,
    },
    end: {
      type: [String, Number],
      default: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isWholeDay () {
      return this.wholeDaySetYn === 'Y'
    },
    isUse () {
      return this.useYn === 'Y'
    },
    dayText () {
      return WEEKEND[this.dayType]
    },
    wholedaySetValue: {
      get () {
        return this.wholeDaySetYn
      },
      set (newValue) {
        this.$emit('update:wholeDaySetYn', newValue)
      },
    },
    startValue: {
      get () {
        return this.start
      },
      set (newValue) {
        this.$emit('update:start', newValue)
      },
    },
    endValue: {
      get () {
        return this.end
      },
      set (newValue) {
        this.$emit('update:end', newValue)
      },
    },
  },
  watch: {
    useYnValue (newValue) {
      this.$emit('update:useYn', newValue ? 'Y' : 'N')
    },
    wholedaySetValue (newValue) {
      // 종일로 설정 시 시간 초기화
      if (newValue === 'Y') {
        this.$emit('update:start', '00:00:00')
        this.$emit('update:end', '00:00:00')
      }
    }
  },
  updated () {
    // api 호출 후 초기값 설정
    this.useYnValue = this.isUse
  },
  created () {
    // 초기값 설정
    this.useYnValue = this.isUse
  },
}
</script>
<style lang="scss" scoped>
.schedule-row {
    display: flex;
    > .form-inline,
    > label {
        margin-right: 30px;
    }
}
</style>
