import Vue from 'vue'

Vue.mixin({
  data () {
    return {}
  },
  methods: {
    log (message) {
      console.log(message)
      return message
    },
    getClientPointerRelativeToStage: (clientX, clientY, stage) => {
      return {
        x: clientX - stage.getContent().getClientRects()[0].left,
        y: clientY - stage.getContent().getClientRects()[0].top,
      }
    },
    getCentroid (points) {
      const X = points.filter((e, i) => i % 2 == 0)
      const Y = points.filter((e, i) => i % 2)
      const movedPoints = []
      const centroid = {
        x: Math.round((Math.max(...X) + Math.min(...X)) / 2),
        y: Math.round((Math.max(...Y) + Math.min(...Y)) / 2),
      }
      for (let i = 0; i < points.length; i++) {
        if (i % 2) {
          movedPoints.push(points[i] - centroid.y)
        } else {
          movedPoints.push(points[i] - centroid.x)
        }
      }
      return {
        ...centroid,
        points: movedPoints,
      }
    },
  },
})
