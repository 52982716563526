export default {
    namespaced: true,
    state: {
      accessToken: null,
    },
    getters: {},
    mutations: {},
    actions: {
      async recoveryIdByEmail ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/id/email`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async recoveryIdByEmail2fa ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/id/email/2fa/${payload.key}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async recoveryIdByMobile ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/id/mobile`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async recoveryIdByMobile2fa ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/id/mobile/2fa/${payload.key}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
  
      async recoveryPasswordByEmail ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/password/email`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
  
      async recoveryPasswordByEmail2fa ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/password/email/2fa/${payload.key}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
  
      async recoveryPasswordByMobile ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/password/mobile`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
  
      async recoveryPasswordByMobile2fa ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/password/mobile/2fa/${payload.key}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
  
      async passwordReset ({ dispatch }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARKONE_MEMBER_API_URL}recovery/password/reset/${payload.key}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
    },
  }
  