<template>
  <nav class="navigation">
    <div class="logo"></div>
    <div class="user" v-if="userName">
      <div>
        <span>{{ userName }}</span> 안녕하세요!
        <!-- <div>foo</div> -->
      </div>
      <div>
        <router-link tag="sp-button" to="/logout" class="big">로그아웃</router-link>
      </div>
    </div>
    <div class="services">
      <span>🌏 어카운트(멤버십)</span>
      <sp-select v-model="selectedAccountId">
        <option v-for="(item, index) in selectableAccountList" :value="item.membershipId" :key="`ac${index}`">
          {{ item.membershipName }}
        </option>
      </sp-select>
      <span>관리자 유형</span>
      <sp-select :disabled="!isSetMembershipId" v-model="selectedManagerType">
        <option v-for="(item, index) in availableManagerTypeList" :value="item.managerType" :key="`mt${index}`">
          {{ item.managerTypeName }}
        </option>
      </sp-select>
      <sp-select v-show="isShowTenantSelectBox" v-model="selectedTenantId">
      <option v-for="(item, index) in availableTenantList" :value="item.tenantId" :key="`tl${index}`">
          {{ item.tenantName }}
        </option>
      </sp-select>
    </div>

    <ul class="main-navigation">
      <template v-for="(menu, i) in tree">
          <li :key="i" v-if="tree != {}">
            <router-link
            :to="menu.menuURL"
            :class="{ home: i == 0 }"
            :style="{ pointerEvent: !menu.menuURL ? 'none' : 'auto' }">
              <template v-if="i == 0"><img src="@/assets/view_quilt.svg" alt="smartoffice" loading="lazy"></template> {{ menu.menuNm }}
            </router-link>
            <ul v-if="menu.child" class="sub-navigation">
              <li
              v-for="(childMenu, j) in menu.child"
              :key="j"
              >
                <router-link :to="childMenu.menuURL" :class="{'active': new RegExp(childMenu.menuURLMatchRegex).test($route.path)}">
                  - {{ childMenu.menuNm }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>
        <li><a href="https://drive.google.com/file/d/1m6YB96wZwCKyWHVV_7xbPu9_GHDRXtel/view?usp=sharing" target="_blank" style="font-weight:bold;color:var(--theme-secondary-color)">어드민 이용 가이드</a></li>
    </ul>
   </nav>
</template>
<script>
export default {
  name: 'Navigation',
  data () {
    return {
      isRefreshed: false,
      isAccountIdChanged: false,
      isTenantLogo: null,
      tenantLogoImageUrl: '',
    }
  },
  async mounted () {
    this.isAccountIdChanged = false
    if (this.selectedAccountId === 0) {
      this.isRefreshed = true
      this.selectedAccountId = window.localStorage.getItem('selected_account_id') || 0
    }
    if (this.selectedManagerType === null) {
      this.selectedManagerType = window.localStorage.getItem('selected_manager_type') || ''
    }
    if (this.selectedTenantId === 0) {
      this.selectedTenantId = window.localStorage.getItem('selected_tenant_id') || 0
    }
    // 입주사 이미지 처리
    this.setTenantLogoImage()
  },
  computed: {
    selectedAccountId: {
      get () {
        return this.$store.state.user.selectedAccountId
      },
      async set (newValue) {
        const oldSelectedManagerType = this.$store.state.user.selectedManagerType

        // 새로운 값 설정
        this.$store.commit('user/setSelectedAccountId', newValue)
        window.localStorage.setItem('selected_account_id', newValue)

        // 새로고침 했을 때 local storage 에서 타입 가져옴
        if (this.isRefreshed) {
          this.selectedManagerType = window.localStorage.getItem('selected_manager_type')
        } else {
          this.isAccountIdChanged = true

          // 관리자 유형 중 입주사 관리자를 우선적으로 자동 선택
          const managerType = this.selectableAccountListByKey[this.selectedAccountId]?.managerTypeList?.find(item => item.managerType === 'tenant-manager')?.managerType
          this.selectedManagerType = managerType || 'membership-manager'
        }

        // 관리자 타입이 안바뀌면 여기서 메뉴 조회
        if (this.selectedManagerType === oldSelectedManagerType) {
          const managerId = this.selectableAccountListByKey[this.selectedAccountId]?.[this.selectedManagerType]?.[0].managerId

          // 새로고침 했을 때
          if (this.isRefreshed) {
            await this.$store.dispatch('user/getMenu', { managerId, needReload: false })
          } else {
          }

        } else { // 관리자 타입이 바뀌면 관리자타입에서 메뉴 조회

        }
      },
    },
    selectedManagerType: {
      get () {
        return this.$store.state.user.selectedManagerType
      },
      async set (newValue) {
        const oldSelectedManagerType = this.$store.state.user.selectedManagerType
        const oldSelectedTenantId = this.$store.state.user.selectedTenantId

        // 새로운 값 설정
        this.$store.commit('user/setSelectedManagerType', newValue)
        window.localStorage.setItem('selected_manager_type', newValue)

        // 관리자 유형에 따른 입주 아이디 처리
        if (newValue === 'membership-manager') {
          // 어카운트 관리자 일 때 tenant id 초기화
          this.selectedTenantId = 0

          // 메뉴 조회
          const managerId = this.selectableAccountListByKey[this.selectedAccountId]?.[this.selectedManagerType]?.[0].managerId

          if (oldSelectedManagerType === null) {
            await this.$store.dispatch('user/getMenu', { managerId, needReload: false })
          } else {
            await this.$store.dispatch('user/getMenu', { managerId, needReload: true })

          }
        } else if (newValue === 'tenant-manager') {
          // 첫 번째 입주사 자동 선택

          // 새로고침 했을 때
          if (this.isRefreshed) {
            this.selectedTenantId = window.localStorage.getItem('selected_tenant_id')
          } else {
            this.selectedTenantId = this.availableTenantList[0]?.tenantId || 0
          }
          // 입주사 아이디가 안바뀌면 여기서 메뉴 조회
          // 어카운트가 달라도 입주사가 같은 경우가 있어서 어카운트 변경 여부 확인
          if (this.isAccountIdChanged && this.selectedTenantId === oldSelectedTenantId) {

            const managerId = this.selectableAccountListByKey[this.selectedAccountId]?.[this.selectedManagerType]?.[0].managerId

            if (oldSelectedManagerType === null) {
              await this.$store.dispatch('user/getMenu', { managerId, needReload: false })

            } else if (this.selectedTenantId !== oldSelectedTenantId) {
              await this.$store.dispatch('user/getMenu', { managerId, needReload: true })
            }

          } else {
            // 입주사 아이디가 바뀌면 입주사 아이디에서 메뉴 조회
          }
        }

        // accountid + managertype 조합 바뀌면 menu 새로조회
      },
    },
    selectedTenantId: {
      get () {
        return this.$store.state.user.selectedTenantId
      },
      async set (newValue) {
        const oldSelectedTenantId = this.$store.state.user.selectedTenantId

        this.$store.commit('user/setSelectedTenantId', newValue)
        window.localStorage.setItem('selected_tenant_id', newValue)

        // 공백일 경우 어카운트 관리자 타입 변경 때 메뉴 조회했으므로, 공백이 아닐때만 메뉴 조회
        if (newValue !== '') {
          // 선택한 값의 manager id 추출해서 메뉴 조회 후 이동
          const managerId = this.selectableAccountListByKey[this.selectedAccountId]?.[this.selectedManagerType]?.[0].managerId

          if (oldSelectedTenantId === 0) {
            await this.$store.dispatch('user/getMenu', { managerId, needReload: false })

          } else {
            await this.$store.dispatch('user/getMenu', { managerId, needReload: true })

          }
        }

        // 입주사 이미지 처리
        this.setTenantLogoImage()
      },
    },
    selectableAccountList () {
      return this.$store.state.user.selectableMembershipList
    },
    selectableAccountListByKey () {
      return this.$store.state.user.selectableMembershipListByKey // key 로 조회하기 위한 객체
    },
    isSetMembershipId () {
      return this.selectedAccountId !== 0
    },
    isShowTenantSelectBox () {
      // 어카운트에 대한 입주사 관리자 권한이 2개 이상 있을 때 노출
      return this.availableTenantList.length > 1
    },
    tree () {
      return this.$store.getters.tree
    },
    userName () {
      return this.$store.getters['user/userName']
    },
    availableManagerTypeList () {
      return this.selectableAccountListByKey[this.selectedAccountId]?.managerTypeList || []
    },
    availableTenantList () {
      return this.selectedManagerType !== 'membership-manager' && this.selectableAccountListByKey[this.selectedAccountId]?.['tenant-manager'] || []
    },
  },
  methods: {
    setTenantLogoImage () {
      if (this.selectedTenantId != 0) {
        const tenantInfo = this.availableTenantList.filter(item => item.tenantId == this.selectedTenantId)[0] ?? {}
        if (tenantInfo.tenantImageUrl != null) {
          this.isTenantLogo = true
          this.tenantLogoImageUrl = tenantInfo.tenantImageUrl
        } else {
          this.isTenantLogo = false
        }
      } else {
        this.isTenantLogo = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.navigation {
  // display: block;
  height: 100%;
  overflow: auto;
  user-select: none;
  padding-bottom: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 320px;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  // transition: width 0.2s cubic-bezier(.5,0,.5,1);
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .active {
    font-weight: 800;
    color: var(--theme-primary-color);
  }
}
.main-navigation {
  margin: 20px 50px 0 50px;
  padding: 0;
  >li {
    font-size: 18px;
    line-height: 20px;
    > a {
      color: #fff;
      display: inline-block;
      margin: 20px 0 5px 0;
    }
  }
}
.sub-navigation {
  padding-left: 20px;

  >li {
    font-size: 16px;
    > a {
      display: inline-block;
      color: #fff;
      margin: 5px 0;
    }
    // list-style-type: '- ';
  }
}
.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;

}
.services {
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;;
  }
  margin: 20px 40px 0 40px;
}
.logo {
  font-family: NanumSquareAc;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 44.2px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 36px;
  img {
    width: 260px;
  }
}

</style>
