import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import usageRoutes from '@/router/usageRoutes'
import settlementRoutes from '@/router/settlementRoutes'

const TenantList = () => import('@/components/tenant/information/List')
const TenantForm = () => import('@/components/tenant/information/Form')
const ManagerList = () => import('@/components/manager/List')
const managerForm = () => import('@/components/manager/Form')
const Error404 = () => import('@/components/common/error/Error404')
const Error403 = () => import('@/components/common/error/Error403')
const Main = () => import('@/components/Main')
const Login = () => import('@/components/login/Login')
const RecoveryId = () => import('@/components/recovery/RecoveryId')
const RecoveryPassword = () => import('@/components/recovery/RecoveryPassword')
const DepartmentPosition = () => import('@/components/tenant/DepartmentPosition')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'login',
      redirect: '/tenant/tenant-business',
    },
  },
  {
    path: '/login/reset/:token',
    name: 'LoginNewPassword',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'newPassword',
      redirect: '/',
    },
  },
  {
    path: '/recovery/id',
    name: 'RecoveryId',
    component: RecoveryId,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/recovery/password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    beforeEnter: async (to, from, next) => {
      let result
      if (await store.dispatch('user/refresh')) {
        result = await store.dispatch('user/logout')
      }
      if (result == 'error') {
        alert('로그아웃에 실패 했습니다. 인터넷 연결을 확인 해 주세요.')
        next(from.fullPath)
      } else {
        if (to.query.returnPage) {
          next({
            path: '/login',
          })
        } else {
          next('/login')
        }
      }
    },
  },
  {
    // 입주사 정보
    path: '/tenant/info',
    name: 'TenantList',
    component: TenantList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사(사업자) 정보 상세
    path: '/tenant/info/business/:id/detail',
    name: 'TenantBusinessDetail',
    component: TenantForm,
    meta: {
      mode: 'business',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사(개인) 정보 상세
    path: '/tenant/info/personal/:id/detail',
    name: 'TenantPersonalDetail',
    component: TenantForm,
    meta: {
      mode: 'personal',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사 관리자 리스트
    path: '/tenant/tenant-manager',
    name: 'TenantManagerList',
    component: ManagerList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사 관리자 상세
    path: '/tenant/tenant-manager/:id/detail',
    name: 'TenantManagerDetail',
    component: managerForm,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/tenant/department-position',
    name: 'DepartmentPosition',
    component: DepartmentPosition,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/contract/information',
    name: 'ContractInformation',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/ContractInformation.vue'),
  },
  {
    path: '/tenant/account-member',
    name: 'AccountMemberPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/tenant/member/MemberPage.vue'),
  },
  {
    path: '/tenant/account-member/:id/edit',
    name: 'AccountMemberEditPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/tenant/member/MemberEditPage.vue'),
  },
  {
    path: '/settlement/list',
    name: 'InvoiceList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceList.vue'),
  },
  {
    path: '/settlement/monthly',
    name: 'InvoiceMonthly',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceMonthly.vue'),
  },
  {
    path: '/settlement/unpaid',
    name: 'InvoiceUnpaid',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceUnpaid.vue'),
  },
  ...usageRoutes,
  ...settlementRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}

// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      next()
      // 입주사 어드민은 권한이 확인된 메뉴만 뿌려주므로 진입 시 개별 페이지에 대한 접근 확인 필요 없음
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        await store.dispatch('user/getSelectableAccountList', { userId: store.getters['user/userId'] })
        next()
        // 페이지 접근 권한 확인 안함
      // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
        })
      }
    }

  // 권한이 필요 없는 페이지
  } else {
    next()
  }
})

export default router
