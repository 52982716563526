const getDefaultState = () => {
    return {
      list: [],
      count: 0,
      originFilter: {},
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 20
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data.memberBulkRegistHistoryList
        state.count = data.totalCount.count
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
    },
    actions: {
        async getMemberBulkRegistHistory ({ commit }, payload) {
            let response = null
            try {
              response = await this._vm.get(
                `${this._vm.SPARKONE_OFFICE_API_URL}account/office/memberBulkRegistHistory`,
                payload,
              )
              commit('setResponse', response.data)
              commit('setOriginFilter', payload)
            } catch (error) {
              if (error.response) {
                response = error
              } else {
                throw error
              }
            }
            return response
          },
    },
  }
